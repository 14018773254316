<template>
  <v-navigation-drawer
    :value="value"
    @input="(v) => $emit('input', v)"
    fixed
    temporary
  >
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="orange--text text--accent-4"
      >
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Главная</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="show('/', '#contacts', $router)"
          active-class=""
        >
          <v-list-item-icon>
            <v-icon>la-id-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Контакты</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item to="/about">
          <v-list-item-icon>
            <v-icon>la-info</v-icon>
          </v-list-item-icon>
          <v-list-item-title>О нас</v-list-item-title>
        </v-list-item> -->

        <!-- <v-divider></v-divider>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>la-sign-{{ $store.getters.isLoggedIn ? 'out' : 'in' }}-alt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.getters.isLoggedIn ? 'Выйти' : 'Вход' }}</v-list-item-title>
            </v-list-item> -->
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import ScrollRouter from "@/mixins/ScrollRouter";

export default {
  name: "HosDrawer",
  mixins: [ScrollRouter],
  props: ["value"],
  data() {
    return {
      group: null,
    };
  },
};
</script>

<style scoped></style>
