<template>
  <v-app>
    <hos-app-bar v-model="drawer"></hos-app-bar>
    <v-main>
      <div ref="header">
        <hos-drawer v-model="drawer"></hos-drawer>
      </div>
      <div ref="content">
        <v-container fluid class="lighten-4 px-0 py-0">
          <router-view></router-view>
        </v-container>
      </div>
      <div ref="footer"><main-footer></main-footer></div>
      <error-modal
        ref="error"
        v-model="$store.state.error.show"
        :error="$store.state.error.value"
      ></error-modal>
    </v-main>
  </v-app>
</template>

<script>
import HosAppBar from "./UI/HosAppBar";
import HosDrawer from "./UI/HosDrawer";
import MainFooter from "./UI/MainFooter";
import ErrorModal from "@/components/UI/ErrorModal.vue";
export default {
  name: "Layout",
  components: {
    HosDrawer,
    HosAppBar,
    MainFooter,
    ErrorModal,
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    this.onResize();

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    onResize() {
      let header = this.$refs.header.offsetHeight;
      let footer = this.$refs.footer.offsetHeight;
      let win = window.innerHeight;
      this.footerPadding = win - footer - header - 1;
      this.$refs.content.style.minHeight = this.footerPadding + "px";
    },
  },
};
</script>

<style></style>
