<template>
  <div class="text-center">
    <v-dialog v-model="value" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 red lighten-2 white--text error-title">
          Внимание
        </v-card-title>

        <v-card-text class="mt-5 text-h6">
          <p>{{ error.Message }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', false)">
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";

export default {
  name: "ErrorModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => {
        return { Code: null, Message: "" };
      },
    },
  },
  mixins: [DateFormat],
};
</script>

<style scoped>
.error-title {
  white-space: pre;
}
</style>
