<template>
  <v-footer color="white">
    <v-container>
      <v-row>
        <v-col cols="12" md="2" id="contacts">
          <v-col>
            <div class="text-h5">Наши контакты</div>
            <div
                class="light-green accent-4 mt-2 mb-8"
                style="height: 2px; width: 15%"
            ></div>
            <div>
              <a
                  class="black--text text-decoration-none"
                  :href="$store.state.company.AddressLink"
                  target="_blank"
              >
                <v-icon color="orange" class="mr-3" large>la-map-marker</v-icon>
                {{ $store.state.company.Address }}
              </a>
            </div>
            <div class="my-5">
              <a
                  class="black--text text-decoration-none"
                  :href="$store.state.company.PhoneLink"
              >
                <v-icon color="orange" class="mr-3" large>la-phone</v-icon>
                {{ $store.state.company.Phone }}
              </a>
            </div>
            <div>
              <a
                  class="black--text text-decoration-none"
                  :href="$store.state.company.EmailLink"
              >
                <v-icon color="orange" class="mr-3" large
                >la-envelope-open
                </v-icon
                >
                {{ $store.state.company.Email }}
              </a>
            </div>
          </v-col>
        </v-col>
        <v-col cols="12" md="2">
          <v-col>
            <div class="text-h5">Информация</div>
            <div
                class="light-green accent-4 mt-2 mb-8"
                style="height: 2px; width: 15%"
            ></div>
            <div>
              <a
                  class="black--text text-decoration-none"
                  href=/about
              >
                О компании
              </a>
            </div>
          </v-col>
          <v-col>
            <div>
              <a
                  class="black--text text-decoration-none"
                  href=/bpo-info
              >
                Реквизиты компании
              </a>
            </div>
          </v-col>
          <v-col>
            <div>
              <a
                  class="black--text text-decoration-none"
                  href=/legalInformation
              >
                Правовая информация
              </a>
            </div>
          </v-col>
          <v-col>
            <div>
              <a
                  class="black--text text-decoration-none"
                  href=/pricingInfo
              >
                Ценообразование
              </a>
            </div>
          </v-col>
          <v-col>
            <div>
              <a
                  class="black--text text-decoration-none"
                  href=/legal
              >
                Оферта
              </a>
            </div>
          </v-col>
        </v-col>

        <v-col cols="2" md="2">
          <v-col>
            <div class="text-h5">Наши партнеры</div>
            <div
                class="light-green accent-4 mt-2 mb-8"
                style="height: 2px; width: 15%"
            ></div>

          </v-col>
          <v-col>
            <v-img style="background: grey" src="../../assets/tkt-list/TCH_logo_Ru.png">
            </v-img>
          </v-col>
        </v-col>

        <v-col cols="12" md="6">
          <v-col>
            <div class="text-h5">Новостная рассылка</div>
            <div
                class="light-green accent-4 mt-2 mb-8"
                style="height: 2px; width: 15%"
            ></div>
            <div class="text-body-1 pr-16">
              Подпишитесь на нашу новостную рассылку и будьте первым кто узнает о
              последних новостях, специальных предложениях, событиях и скидках.
            </div>
            <v-row class="mt-5 mb-5" align="start">
              <v-col cols="12" sm="7" class="py-0">
                <v-text-field
                    flat
                    color="orange"
                    outlined
                    label="Введите свой email"
                    v-model="emailNewsletter"
                    :rules="emailRule"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 py-sm-1 text-center text-sm-left">
                <v-btn dark large color="orange" elevation="5" @click="subscribe"
                >Подписаться
                </v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-img src="../../assets/tkt-list/TCH_logo_Ru.png"></v-img> -->
        </v-col>
      </v-row>
      <v-row justify="center" class="text--disabled">
        &copy;Copyright {{ $store.state.company.Name }}
        {{ new Date().getFullYear() }}
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Validator from "@/mixins/Validators";

export default {
  name: "MainFooter",
  mixins: [Validator],
  data() {
    return {
      emailNewsletter: "",
    };
  },
  methods: {
    subscribe() {
      if (!!this.emailNewsletter && this.validateEmail(this.emailNewsletter)) {
        console.log("Вы нажали подписаться)");
      }
    },
  },
};
</script>
