import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./services/store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import enums from "./services/enums";
import VueYandexMetrika from "vue-yandex-metrika";

//import Authenticator from "./Authenticator";

Vue.prototype.$axios = axios;
axios.defaults.baseURL = store.getters.api;
Vue.prototype.$store = store;
Vue.config.productionTip = false;
Vue.prototype.$enums = enums;

Vue.use(VueTheMask);
//axios.defaults.headers.common["IsTest"] = true;
//     Vue.use(VueYandexMetrika, {
//       id:  95074779,
//       router: router,
//       env: process.env.NODE_ENV
//       // other options
//     });
Vue.use(VueYandexMetrika, {
  id: 95074779,
  router: router,
  env: process.env.NODE_ENV,
  // other options
});

function mount() {
  new Vue({
    vuetify,
    render: (h) => h(App),
    router: router,
  }).$mount("#app");
}
mount();
// Authenticator.firstCheckToken().then(() =>
//   Authenticator.login().then(() => {
//     mount();
//   })
// );
//Authenticator.login().then(() => { mount() })
