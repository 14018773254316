const TicketStatusCodes = Object.freeze({
    Unknown: 0,
    Booked: 2,
    Bought: 3,
    BookСanceled: 4,
    Void: 5,
    Returned: 6,
    NotChanged: 7,
    VoidBooked: 8,  
});

//  this.$enums.Endpoints.Other
const Endpoints = Object.freeze({
Order: {
    Search: "/Order/Search",
    Fare: "/Order/Fare",
    Book: "/Order/Book",
    Buy: "/Order/Buy",
    Status: "/Order/Status",
    Cancel: "/Order/Cancel",
    Void: "/Order/Void",
    Refund: "/Order/Refund",
    FareRules: "/Order/FareRules",
    SeatMap: "/Order/SeatMap",
},
Data:{
    Orders: "/Data/Orders/Get",
    Countries: "/Data/Countries/Get",
    Stations: "/Data/Stations/Get",
    Carriers: "/Data/Carriers/Get",
    Pdf: "/Data/Pdf/Get",
    QR: "/Data/QR/Get",
    TopQrSolds: "/Data/QR/Top",
},
Other:{
    SendMail: "/Other/Mail/Send",
    QrReport: "/Other/QR/Report",
},
Authorization:{
    Login: "/Authorization/Login",
    LoginByToken: "/Authorization/LoginByToken",
    UpdateToken: "/Authorization/UpdateToken",
    CheckToken: "/Authorization/CheckToken",
},
Account:{
    Get: "/Account/Get",
    Create: "/Account/Create",
    Edit: "/Account/Edit",
    Delete: "/Account/Delete",
},
Payment:{
    QrCreate: "/Payment/QR/Create",
    QrStatus: "/Payment/QR/Status",
},
Sms:{
    Get: "/SMS/CanLeaveReview",
    Post: "SMS/SendFeedback",
}
});

export default { TicketStatusCodes, Endpoints };
