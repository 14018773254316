import store from "./store";
import axios from "axios";

export default {
  login: async () => {
    let user = {
      Email: "guest@bpo.travel",
      Password: "guest123",
    };
    let response = await axios.post("Authorization/Login", user);
    if (response.status === 200) {
      store.commit("setToken", response.data.token);
      store.commit("setUser", response.data.user);

      console.log(
        "Successful login as a guest! [" + new Date(Date.now()) + "]"
      );
      return true;
    }

    return false;
  },

  checkToken: async () => {
    let token = store.getters.token;
    if (token === null) {
      await axios.get(`Authorization/CheckToken?token=${token}`);
      return true;
    }
    return false;
  },
};
