export default {
    data() {
        return {
            requiredRule: [
                (v) => !!v || 'Это поле обязательно к заполнению!',
            ],
            lnRule: [
                (v) => !!v || 'Это поле обязательно к заполнению!',
                (v) => !(/\s/g.test(v)) || "Фамилия не может содержать более одного слова или пробелы!",
            ],
            phoneRule: [
                (v) => !!v || 'Это поле обязательно к заполнению!',
                (v) => this.validatePhone(v) || "Номер телефона некорректен!",
            ],
            emailRule: [
                (v) => !!v || 'Это поле обязательно к заполнению!',
                (v) => this.validateEmail(v) || "Электронная почта некорректна!",
            ],
            docNumberRule: [
                (v) => !!v || 'Это поле обязательно к заполнению!',
                (v) => String(v).length > 6 || 'Длина номера документа не может быть меньше 6',
                (v) => String(v).length < 17 || 'Длина номера документа не может быть больше 17',
                (v) => this.validateDocNumber(v) || 'Номер документа может содержать только символы латинского и русского алфавита (кроме букв «Ё» и «Ъ»), а также цифры!',
            ],
        }
    },
    methods: {
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validatePhone(phone) {
            const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
            return re.test(String(phone).toLowerCase());
        },
        validateDocNumber(number) {
            const re = /^[0-9a-zA-ZА-Яа-я]{6,17}$/;
            return re.test(String(number).toLowerCase());
        }
    }
}
