export default {
    methods: {
        show(original, hash, router) {
            let el = document.querySelector(hash);
            if (el) {
                if(router.currentRoute.hash != hash){
                    router.push(router.currentRoute.path + hash)
                } else {
                    el.scrollIntoView({
                        behavior: "smooth"
                    })
                }
            } else {
                router.push(original + hash)
            }
        }
    }
}