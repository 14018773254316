import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    email: "",
    phone: "",
    token: "",
    api:
      process.env.NODE_ENV === "development"
        ? "https://localhost:7011/api"
        : "https://api.bpo.travel/api",
    //: "https://api.bpo.travel/api",
    bpoServiceApi:
      process.env.NODE_ENV === "development"
        ? "https://localhost:44344/api"
        : // ? "https://test-api.bpo.travel/api"
          "https://bpo.travel",

    company: {
      Name: "БПО",
      Phone: "+7(495) 461 00 11",
      PhoneLink: "tel:+74954610011",
      Address: "Москва, ул.Юннатов, д.18",
      AddressLink:
        "https://2gis.ru/moscow/search/%D1%8E%D0%BD%D0%B0%D1%82%D0%BE%D0%B2%2018/geo/4504235282677586?m=37.558967%2C55.802872%2F18.03%2Fp%2F45%2Fr%2F-95.24",
      Email: "info@bpo.travel",
      EmailLink: "mailto:info@bpo.travel",
    },

    // Error
    error: {
      show: false,
      value: {
        Code: "",
        Message: "",
      },
    },
  },
  mutations: {
    setEmail(state, value) {
      state.email = value;
    },
    setToken(state, token) {
      localStorage.setItem("Token", token);
      state.token = token;
      axios.defaults.headers.common["Authorization"] = "bearer " + token;
    },
    setUser(state, user) {
      state.user = { ...user };
    },
  },
  getters: {
    api: (state) => state.api,
    bpoServiceApi: (state) => state.bpoServiceApi,
    email: (state) => state.email,
    token: (state) => state.token,
  },
  actions: {
    async updateToken() {
      console.log("Обновляемый токен " + this.state.token);
      let response = await axios.get(
        `/Authorization/UpdateToken?token=${this.state.token}`
      );
      console.log(response);
      console.warn("Обновлен токен - " + response.data);
      this.commit("setToken", response.data);
    },
  },
});
