import Router from "vue-router";
import Authenticator from "./services/Authenticator";
import Vue from "vue";
// import VueYandexMetrika from "vue-yandex-metrika";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      // props: (router) => ({next: router.query.next}),
      component: () => import("@/components/Pages/Home.vue"),
    },
    {
      path: "/home",
      redirect: "/",
    },
    // {s
    //     path: "/yandex_ae88c15c32484e78.html",
    //     name: "yandex_ae88c15c32484e78.html",
    //     component: () => import("@/components/YandexMetrica.vue"),
    // },
    {
      path: "/about",
      name: "about",
      component: () => import("@/components/Pages/About.vue"),
    },
    {
      path: "/career",
      name: "career",
      component: () => import("@/components/Pages/Career.vue"),
    },
    {
      path: "/legalInformation",
      name: "legalInformation",
      component: () => import("@/components/Pages/LegalInformation.vue"),
    },
    {
      path: "/pricingInfo",
      name: "pricingInfo",
      component: () => import("@/components/Pages/PricingInfo.vue"),
    },
    {
      path: "/legal",
      name: "legal",
      component: () => import("@/components/Pages/Legal.vue"),
    },
    {
      path: "/bpo-info",
      name: "bpo-info",
      component: () => import("@/components/Pages/PaymentData.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("@/components/Pages/PrivacyPolicy.vue"),
    },
    {
      path: "/found-tickets",
      name: "found-tickets",
      component: () => import("@/components/Pages/FoundedTickets.vue"),
      props: (router) => ({
        cls: router.query.cls,
        src: router.query.src,
        dest: router.query.dest,
        date: router.query.date,
        back: router.query.back,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        direct: router.query.direct,
        city1: router.query.city1,
        city2: router.query.city2,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/book-tkt",
      name: "book-tkt",
      component: () => import("@/components/Pages/BookTkt.vue"),
      props: (router) => ({
        segments: router.query.segments,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        pult: router.query.pult,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/buy-ticket",
      name: "buy-ticket",
      component: () => import("@/components/Pages/BuyTicket.vue"),
      props: (router) => ({
        adt: Number(router.query.adt),
        cnn: Number(router.query.cnn),
        inf: Number(router.query.inf),
        provider: router.query.provider,
        id: router.query.id,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/booked-ticket",
      name: "booked-ticket",
      component: () => import("@/components/Pages/BookedTicket.vue"),
      props: (router) => ({
        id: router.query.id,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/processing",
      name: "processing",
      component: () => import("@/components/Pages/Processing.vue"),
      props: (router) => ({
        orderNumber: router.query.orderNumber,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/show-receipt",
      name: "show-receipt",
      component: () => import("@/components/Pages/ShowReceipt.vue"),
      props: (router) => ({
        order: router.query.order,
        error: router.query.error,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/client-receipt/:id",
      name: "client-receipt",
      component: () => import("@/components/Pages/ShowClientReceipt.vue"),
      props: (router) => ({
        id: router.params.id,
      }),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/review/:id",
      name: "review",
      component: () => import("@/components/Pages/Review.vue"),
    },
  ],
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
});

router.beforeEach(async (to, from, next) => {
  if (Authenticator.login() === true) {
    next();
  } else {
    let problem = false;
    try {
      problem = await Authenticator.checkToken();
    } catch {
      problem = false;
    }
    if (problem) {
      next();
      console.log("Problem");
    } else {
      next();
    }
  }
});

export default router;
