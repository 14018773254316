<template>
  <header>
    <v-app-bar
        v-if="$vuetify.breakpoint.mdAndUp"
        color="rgba(0,0,0,.7)"
        dense
        app
        extension-height="65"
        hide-on-scroll
    >
      <div class="white--text">
        <a
            class="mx-2 white--text text-decoration-none"
            :href="$store.state.company.AddressLink"
            target="_blank"
        >
          <v-icon color="white">la-map-marker</v-icon>
          {{ $store.state.company.Address }}
        </a>
      </div>
      <v-spacer></v-spacer>
      <div>
        <a
            class="mx-2 white--text text-decoration-none"
            :href="$store.state.company.PhoneLink"
        >
          <v-icon color="white">la-phone</v-icon>
          {{ $store.state.company.Phone }}
        </a>
        <a
            class="mx-2 white--text text-decoration-none"
            :href="$store.state.company.EmailLink"
        >
          <v-icon color="white">la-envelope-open</v-icon>
          {{ $store.state.company.Email }}
        </a>
      </div>

      <template slot="extension">
        <v-toolbar-title>
          <router-link to="/">
            <v-img
                :src="require('@/assets/logo.jpg')"
                contain
                max-height="40"
                max-width="100"
            ></v-img>
          </router-link>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="fill-height mx-1 btn">
          <div
              v-if="$route.name === '/'"
              style="height: 5%"
              class="light-green accent-3"
          ></div>
          <div
              v-else
              style="height: 5%;"
              class="light-green accent-3 broder-top"
          ></div>
          <v-btn text to="/" large tile height="95%">
            Главная
          </v-btn>
        </div>

       <div class="fill-height mx-1 btn">
         <div v-if="$route.name ==='about'" style="height: 5%" class="light-green accent-3"></div>
         <div v-else style="height: 5%;" class="light-green accent-3 broder-top"></div>
         <v-btn text to="/about" large tile height='95%'>
           О нас
         </v-btn>
       </div>
        <div class="fill-height mx-1 btn">
          <div v-if="$route.name ==='career'" style="height: 5%" class="light-green accent-3"></div>
          <div v-else style="height: 5%;" class="light-green accent-3 broder-top"></div>
          <v-btn text to="/career" large tile height='95%'>
            Карьера
          </v-btn>
        </div>

        <div class="fill-height mx-1 btn">
          <div
              style="height: 5%;"
              class="light-green accent-3 broder-top"
          ></div>
          <v-btn
              text
              large
              tile
              height="95%"
              @click="show('/', '#contacts', $router)"
          >
            Контакты
          </v-btn>
        </div>

        <div class="fill-height mx-1 btn">
          <div
              style="height: 5%;"
              class="light-green accent-3 broder-top"
          ></div>
          <v-btn
              text
              large
              tile
              height="95%"
              @click="show('/', '#find-tkt-desctop', $router)"
          >
            <v-icon>la-search</v-icon>
          </v-btn>
        </div>
      </template>
    </v-app-bar>

    <v-app-bar v-else color="white" dense app height="50" hide-on-scroll>
      <v-app-bar-nav-icon @click="$emit('input', !value)">
        <v-icon>la-list</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/">
          <v-img
              :src="require('@/assets/logo.jpg')"
              contain
              max-height="40"
              max-width="100"
          ></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <div class="text-center">
        <v-menu :close-delay="3000">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">la-ellipsis-v</v-icon>
          </template>

          <v-list color="#5D31F3">
            <v-list-item @click="show('/', '#find-tkt-phone', $router)">
              <v-icon color="white">la-search</v-icon>
            </v-list-item>

            <v-list-item link :href="$store.state.company.PhoneLink">
              <v-icon color="white">la-phone</v-icon>
            </v-list-item>

            <v-list-item link :href="$store.state.company.EmailLink">
              <v-icon color="white">la-envelope-open</v-icon>
            </v-list-item>

            <v-list-item
                link
                :href="$store.state.company.AddresLink"
                target="_blank"
            >
              <v-icon color="white">la-map-marker</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </header>
</template>

<script>
import ScrollRouter from "@/mixins/ScrollRouter";

export default {
  name: "HosAppBar",
  mixins: [ScrollRouter],
  props: ["value"],
};
</script>

<style scoped>
::v-deep div.v-toolbar__extension {
  background-color: white;
}

.btn .broder-top {
  visibility: hidden;
}

.btn:hover .broder-top {
  visibility: visible;
}
</style>
