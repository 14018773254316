<template>
  <layout style="overflow-x: hidden"></layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  name: "App",
  components: { Layout },
};
</script>
